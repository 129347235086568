import React, {Component} from 'react';
import FullPageLoading from '../component/FullPageLoading';
import userManager from '../utils/userManager';

class Signout extends Component {
    render() {
        return <FullPageLoading/>;
    }

    componentDidMount() {
        userManager.signoutRedirect();
    }
}

export default Signout;
