import {InMemoryWebStorage, WebStorageStateStore} from 'oidc-client';
import {createUserManager} from 'redux-oidc';

const userManagerConfig = {
    client_id: process.env.REACT_APP_OAUTH2_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    response_type: 'code',
    scope: 'openid profile email',
    authority: process.env.REACT_APP_OAUTH2_AUTHORITY,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent-renew`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/signout-callback`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: false,
    userStore: new WebStorageStateStore({store: new InMemoryWebStorage()}),
};

export default createUserManager(userManagerConfig);

